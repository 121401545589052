import React from "react"
import ReactMarkdown from "react-markdown"

const Content = ({ main1, main2, side1, side2, className }) => {
  return (
    <>
      <section className={`atelier-options change-header tr-bg ${className}`}>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-sm-12 col-md-6 col-lg-6 content-lines">
              <ReactMarkdown children={main1} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 content-lines">
              <ReactMarkdown children={side1} />
            </div>
          </div>
        </div>
      </section>

      <section className="atelier-option2 change-header">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-sm-12 col-md-6 col-lg-6 content-lines">
              {/* <h1 className="h1-one">{Heading}</h1> */}
              <br />
              <ReactMarkdown children={main2} className="h1-two" />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 content-lines">
              {/* <h3 className="h3-one">{pOne}</h3>
              <h3 className="h3-one">{pTwo}</h3>
              <h3>{pThree}</h3> */}
              <ReactMarkdown children={side2} className="h3-one" />
            </div>
            <br />
          </div>
        </div>
      </section>
    </>
  )
}

export default Content
